<template>
  <div class="data-box">
    <div class="data-box-body">
      <div class="body-div" v-if="showType == 4">
        <div class="body-div-item">
          <span class="span-key">消费数量</span>
          <span class="span-value">{{successInfo.number + successInfo.unit}}</span>
        </div>
        <div class="body-div-item">
          <span class="span-key">消费金额</span>
          <span class="span-value">{{Number(successInfo.money).toFixed(2) + "元"}}</span>
        </div>
      </div>
      <div class="body-success">
        <div class="body-div-success">
          <van-image class="success-image" :src="require('../../assets/image/success.png')"/>
          <div class="success-title" v-if="showType == 1">发放成功</div>
          <div class="success-title" v-else-if="showType == 2">入住办理成功</div>
          <div class="success-title" v-else-if="showType == 3">办理退房成功</div>
          <div class="success-title" v-else>消费确认成功</div>
          <div class="success-time">{{successInfo.dateTime}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "par_success",
  data() {
    return {
      successInfo: {},
      showType: 0
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.successInfo = this.$route.query;
      that.showType = that.successInfo.type;
      that.successInfo.dateTime = that.$tool.dateTimeFormat(new Date());
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>